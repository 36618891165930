@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/EuclidCircularA-Regular-WebS.woff2) format("woff2"),
       url(./assets/fonts/EuclidCircularA-Regular-WebS.woff) format("woff"),
       url(./assets/fonts/EuclidCircularA-Regular-WebS.ttf) format("truetype")
}

@font-face {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 500;
  src: url(./assets/fonts/EuclidCircularA-Medium-WebS.woff2) format("woff2"),
       url(./assets/fonts/EuclidCircularA-Medium-WebS.woff) format("woff"),
       url(./assets/fonts/EuclidCircularA-Medium-WebS.ttf) format("truetype")
}

@font-face {
  font-family: "Euclid Circular A";
  font-style: normal;
  font-weight: 600;
  src: url(./assets/fonts/EuclidCircularA-Semibold-WebS.woff2) format("woff2"),
       url(./assets/fonts/EuclidCircularA-Semibold-WebS.woff) format("woff"),
       url(./assets/fonts/EuclidCircularA-Semibold-WebS.ttf) format("truetype")
}
